import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactCalendar from "react-calendar";
import Calendar from "react-calendar";
import { format } from "date-fns";

import "react-calendar/dist/Calendar.css";

import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Popper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//project imports
import { bolGetPpmodSummaryTreeApi, bolUpdateApi, bolGetMonthlyPpmodSummaryApi, bolGetExtractedFileData } from "../Common/apiCalls";
import { extractFilenameFromPath } from "../Common/utils";
import EditableJsonDialog from "../Components/JsonEditer";
import ReusableSnackbar from "../Common/snackbar";

import "./Dashboardv3.css";
import ChartjsBarChart from "../Components/ChartjsBarChart";

const PpmodInsightsPage = () => {
    const navigate = useNavigate();
  
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [openFile, setOpenFile] = useState(false);
    const [fileSource, setFileSource] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentDateData, setCurrentDateData] = useState(null); // State to store data for the selected date
    const [jsonData, setJsonData] = useState(null);
    const [jsonDataId, setJsonDataId] = useState(null);
  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [dateInfo, setDateInfo] = useState({});
  
    const [selectedHub, setSelectedHub] = useState("TTL");
    const [expandedItems, setExpandedItems] = useState([]);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [calendarAnchor, setCalendarAnchor] = useState(null);
    let currentRenderedCalendarDate = [];
    const sanitizeId = (id) => id.replace(/[^a-zA-Z0-9-_]/g, '_');
  
    // Function to fetch the tree data for a particular date
    const fetchTreeDataForDate = async (date) => {
      setIsLoading(true);
      const formattedDate = format(date, "yyyy-MM-dd");
      const apiResponse = await bolGetPpmodSummaryTreeApi(formattedDate, handleAlert);
      if (apiResponse?.response_data) {
        setCurrentDateData(apiResponse.response_data);
      }
      setIsLoading(false);
      return apiResponse.response_data;
    };
  
    const handleChangeDateButtonClick = (event) => {
      setCalendarAnchor(event.currentTarget);
      setIsCalendarVisible(!isCalendarVisible);
    };
  
    const handleCalendarDateChange = (date) => {
      const oldDate = selectedDate;
      setSelectedDate(date);
      fetchTreeDataForDate(date).then((results) => {
        if(results){
          const expandDate = Object.keys(results)[0];
          const expandHub = `${Object.keys(results)[0]}_${selectedHub}`;
          setExpandedItems((oldExpanded) => {
            if (oldExpanded.length === 0) {
              return [expandDate, expandHub];
            } else {
              return oldExpanded.map((item) => {
                const parts = item.split("_");
                parts[0] = expandDate;
                return parts.join("_");
              });
            }
          });
          
        }
      });
      setIsCalendarVisible(false);
      setOpenFile(false);
    };
  
    // Fetch tree data for a particular month
    const fetchTreeDataForMonth = async (month, year) => {
      setIsLoading(true);
      try {
        const apiResponse = await bolGetMonthlyPpmodSummaryApi(month, year, handleAlert);
        if (apiResponse?.response_data) {
          transformApiResponse(apiResponse.response_data);
        } else {
          setDateInfo({});
          console.log("No response data");
        }
      } catch (error) {
        setDateInfo({});
        console.log(error);
      }
      setIsLoading(false);
    };
  
    // Fetch file view and extracted data for file
    const fetchFileExtractedData = async (file_raw, date, selectedFileName) => {
      // setIsLoading(true);
      setFileSource(file_raw);
      try {
        const apiResponse = await bolGetExtractedFileData(date, selectedFileName, handleAlert);
        if (apiResponse?.response_data) {
          setJsonData(apiResponse?.response_data?.data_extracted);
          setJsonDataId(apiResponse?.response_data?.id);
        } else {
          console.log("No response data");
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
  
    const transformApiResponse = (data) => {
      const transformedData = {};
  
      data.forEach(({ ship_date, num_pallets, _total, _bol_count, LAX, JFK, ORD, DFW, ATL, NA }) => {
        const formattedDate = format(new Date(ship_date), "yyyy-MM-dd");
  
        let total_count = _total ? _total : num_pallets;
  
        transformedData[formattedDate] = {
          TTL: { pallet: total_count !== null ? total_count : null, bol: _bol_count !== null ? _bol_count : null },
          ORD: { pallet: ORD?._total !== null ? ORD?._total : null, bol: ORD?._bol_count !== null ? ORD?._bol_count : null },
          LAX: { pallet: LAX?._total !== null ? LAX?._total : null, bol: LAX?._bol_count !== null ? LAX?._bol_count : null },
          JFK: { pallet: JFK?._total !== null ? JFK?._total : null, bol: JFK?._bol_count !== null ? JFK?._bol_count : null },
          DFW: { pallet: DFW?._total !== null ? DFW?._total : null, bol: DFW?._bol_count !== null ? DFW?._bol_count : null },
          ATL: { pallet: ATL?._total !== null ? ATL?._total : null, bol: ATL?._bol_count !== null ? ATL?._bol_count : null },
          NR: { pallet: NA?._total !== null ? NA?._total : null, bol: NA?._bol_count !== null ? NA?._bol_count : null },
        };
      });
  
      setDateInfo(transformedData);
    };
  
    // Function to handle when the calendar view changes
    const handleActiveStartDateChange = ({ activeStartDate }) => {
      setActiveStartDate(activeStartDate);
      const month = activeStartDate.getMonth() + 1;
      const year = activeStartDate.getFullYear();
      fetchTreeDataForMonth(month, year);
    };
  
    useEffect(() => {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      fetchTreeDataForMonth(currentMonth, currentYear);
    }, []);
  
    // Function to handle date click from the calendar
    const handleDateClick = (date, event) => {
      const dayOfWeek = date.getDay();
      // if (dayOfWeek === 6) {
      //   // Prevent clicks on Saturdays
      //   event.preventDefault();
      //   event.stopPropagation();
      // } else {
      setSelectedDate(date);
      fetchTreeDataForDate(date).then((results) => {
        if(results){
          const expandDate = Object.keys(results)[0];
          const expandHub = `${Object.keys(results)[0]}_${selectedHub}`;
  
        setExpandedItems((oldExpanded) => (oldExpanded.length === 0 ? [expandDate, expandHub] : []));
        }
      });
      setOpenDialog(true);
      // }
    };
  
    const handleExpandedItemsChange = (event, itemIds) => {
      setExpandedItems(itemIds);
    };
  
    // Handle closing the dialog
    const handleCloseDialog = () => {
      setOpenFile(false);
      setOpenDialog(false);
      setCurrentDateData(null); // Clear data when closing
      setExpandedItems([]);
    };
  
    const openFileViewer = (file_raw) => {
      const tempFileName = extractFilenameFromPath(file_raw);
      const cleanedFileName = tempFileName.replace(/-processed\.png$/, "");
      const tempFormattedDate = format(new Date(selectedDate), "yyyy-MM-dd");
      fetchFileExtractedData(file_raw, tempFormattedDate, cleanedFileName);
      setOpenFile(true);
    };
  
    const closeFileViewer = () => {
      setOpenFile(false);
      setFileSource(null);
      setJsonData(null);
    };
  
    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertSeverity(alertSeverity);
  
      if (isLoggedOut) {
        sessionStorage.clear();
        sessionStorage.setItem("authToken", "__logged_out__");
        navigate("/");
      }
    };
  
    const handleCloseAlert = () => {
      setShowAlert(false);
    };
  
    const handleSaveJson = async (jsonData, jsonDataId) => {
      // Call API and if API gives 202, then update allData
      // setIsLoading(true);
      setJsonDataId(null);
      const apiResponseData = await bolUpdateApi(jsonData, jsonDataId, handleAlert);
      if (apiResponseData?.response_data) {
        setShowAlert(true);
        setAlertMessage("Successfully saved!");
        setAlertSeverity("success");
        setJsonDataId(apiResponseData?.response_data?.id);
      } else {
        console.log("Error");
      }
      setIsLoading(false);
    };
  
    const getTileClassName = ({ date, view }) => {
      let classes = "custom-tile"; // Always apply the custom-tile class
  
      if (view === "month") {
        const dayOfMonth = date.getDate();
        const dayOfWeek = date.getDay();
  
        if (dayOfMonth % 2 !== 0) {
          classes += " odd-date"; // Add odd-date class for odd dates
        }
  
        if (dayOfWeek === 6) {
          classes += " saturday-tile"; // Add saturday-tile class for Saturdays
        }
      }
      return classes;
    };
  
    const handleHubChange = (event) => {
      // console.log("selected ", event.target.value);
      setSelectedHub(event.target.value);
    };
  
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ marginTop: 0 }}>
          <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity} />
          { isLoading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}>
              <CircularProgress />
            </div>
          ) : (
            <>
            <Box sx={{ marginBottom: 1, display: "flex", justifyContent: "space-between", alignItems: "center", width: { xs: "90vw", md: "70vw" } }}>
              <FormControl size="small" sx={{ width: "120px" }}>
                <InputLabel id="demo-simple-select-label">Hub</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedHub} label="Hub" onChange={handleHubChange}>
                  <MenuItem value={"TTL"}>Total</MenuItem>
                  <MenuItem value={"ORD"}>ORD</MenuItem>
                  <MenuItem value={"LAX"}>LAX</MenuItem>
                  <MenuItem value={"JFK"}>JFK</MenuItem>
                  <MenuItem value={"DFW"}>DFW</MenuItem>
                  <MenuItem value={"ATL"}>ATL</MenuItem>
                  <MenuItem value={"NR"}>NR</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="h6">Select a Date</Typography>
              <Typography variant="h6" sx={{ opacity: 0, cursor: "default" }}>
                Select a Date
              </Typography>
            </Box>
  
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: { xs: "column", md: "row" },
                width: "100%",
                height: "100%",
                margin: "0 auto",
                gap: 2,
              }}>
              <ReactCalendar
                className="react-calendar-modified"
                value={selectedDate}
                // onClickDay={handleDateClick}
                onClickDay={(value, event) => handleDateClick(value, event)}
                onActiveStartDateChange={handleActiveStartDateChange}
                activeStartDate={activeStartDate}
                tileClassName={getTileClassName}
                view="month"
                tileContent={({ date, view }) => {
                  const formattedDate = format(date, "yyyy-MM-dd");
  
                  currentRenderedCalendarDate.push(formattedDate);
  
                  return dateInfo[formattedDate] ? (
                    <div className="tile-content">
                      <Typography
                        sx={{
                          fontSize: 9,
                          fontStyle: "italic",
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translateX(-50%)", // Center horizontally
                          marginTop: 1,
                        }}>
                        {selectedHub.split("_")[0].toUpperCase()}
                      </Typography>
                      <Typography variant="body2" sx={{}}>
                        # Pallet: <strong style={{ color: "#000000" }}>{dateInfo[formattedDate][selectedHub]["pallet"]}</strong>
                      </Typography>
                      <Typography sx={{ fontSize: 10, display: dateInfo[formattedDate][selectedHub]["bol"] ? "block" : "none" }}>
                        (# BOL: <strong style={{ color: "#000000" }}>{dateInfo[formattedDate][selectedHub]["bol"]}</strong>)
                      </Typography>
                    </div>
                  ) : null;
                }}
                style={{
                  width: "80%",
                  height: "80vh",
                  maxWidth: "100%",
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", mt: 10, mr: -2 }}>
                <ChartjsBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={0} selectedHub={selectedHub} />
                <ChartjsBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={1} selectedHub={selectedHub} />
                <ChartjsBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={2} selectedHub={selectedHub} />
                <ChartjsBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={3} selectedHub={selectedHub} />
                <ChartjsBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={4} selectedHub={selectedHub} />
                <ChartjsBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={5} selectedHub={selectedHub} />
                {/* <MuiBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={0} selectedHub={selectedHub} /> 
                <MuiBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={1} selectedHub={selectedHub} />
                <MuiBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={2} selectedHub={selectedHub} />
                <MuiBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={3} selectedHub={selectedHub} />
                <MuiBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={4} selectedHub={selectedHub} />
                <MuiBarChart chartDetailData={dateInfo} dates={currentRenderedCalendarDate} weekNumber={5} selectedHub={selectedHub} /> */}
              </Box>
            </Box>
            </>
          )}
  
          {/* Dialog to show tree structure */}
          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth={"xl"}>
            <DialogTitle>
              Insights for {format(selectedDate, "MMM dd, yyyy")}
              {!isCalendarVisible ? (
                <Tooltip title="Change Date">
                  <IconButton 
                    color="primary" 
                    onClick={handleChangeDateButtonClick}
                    sx={{ marginLeft: "10px" }} 
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Close">
                  <IconButton 
                    color="primary" 
                    onClick={handleChangeDateButtonClick}
                    sx={{ marginLeft: "10px" }} 
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </DialogTitle>
            <DialogContent>
              {isLoading ? (
                <Box sx={{ width: { xs: 400, md: "40vw" }, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress />
                </Box>
              ) : currentDateData ? (
                <Box sx={{ width: "100%", height: openFile && "60vh", display: "flex", justifyContent: "start", alignItems: "center", gap: 3 }}>
                  <SimpleTreeView expandedItems={expandedItems} onExpandedItemsChange={handleExpandedItemsChange} sx={{ width: { xs: 400, md: "40vw" }, height: "100%" }}>
                    {Object.entries(currentDateData).map(([emailDate, treeSummary]) => (
                      <TreeItem
                        sx={{ mt: 1, backgroundColor: "#ffffff", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", borderRadius: "4px", paddingY: 1, paddingX: 2 }}
                        itemId={sanitizeId(emailDate)}
                        label={
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography>{emailDate}</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>{treeSummary?._total}</Typography>
                          </div>
                        }>
                        {Object.entries(treeSummary).map(([hub, hubSummary]) => {
                          if (!hub.startsWith("_"))
                            return (
                              <TreeItem
                                sx={{ borderLeft: "1px solid #d7d7d7", paddingY: 1, paddingX: 2 }}
                                itemId={sanitizeId(`${emailDate}_${hub}`)}
                                label={
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography>{hub}</Typography>
                                    <Typography sx={{ fontWeight: "bold" }}>{hubSummary?._total}</Typography>
                                  </div>
                                }>
                                {Object.entries(hubSummary).map(([deliveryFacility, deliveryFacilitySummary]) => {
                                  if (!deliveryFacility.startsWith("_"))
                                    return (
                                      <TreeItem
                                        sx={{ borderLeft: "1px solid #d7d7d7", paddingY: 1, paddingX: 2 }}
                                        itemId={`${emailDate}_${hub}_${deliveryFacility}`}
                                        label={
                                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography>{deliveryFacility}</Typography>
                                            <Typography sx={{ fontWeight: "bold" }}>{deliveryFacilitySummary?._total}</Typography>
                                          </div>
                                        }
                                      >
                                        {Object.entries(deliveryFacilitySummary).map(([key, value]) => {
                                            if (key.startsWith("_")) {
                                                return null;
                                            }

                                            if (typeof value === "object" && !Array.isArray(value)) {
                                                return (
                                                    <TreeItem
                                                    sx={{ borderLeft: "1px solid #d7d7d7", paddingY: 1, paddingX: 2 }}
                                                    itemId={sanitizeId(`${emailDate}_${hub}_${deliveryFacility}_${key}`)}
                                                    label={
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Typography>{key}</Typography>
                                                        <Typography sx={{ fontWeight: "bold" }}>{value?._total}</Typography>
                                                        </div>
                                                    }>
                                                    {Object.entries(value).map(([nestedKey, nestedValue]) => {
                                                        if (nestedKey.startsWith("blob://")) {
                                                        return (
                                                            <TreeItem
                                                            sx={{ borderLeft: "1px solid #d7d7d7", paddingY: 1, paddingX: 2 }}
                                                            itemId={sanitizeId(`${emailDate}_${hub}_${deliveryFacility}_${key}_${nestedKey}`)}
                                                            label={
                                                                <div
                                                                style={{ display: "flex", justifyContent: "space-between", gap: 3 }}
                                                                onClick={() => {
                                                                    openFileViewer(nestedKey);
                                                                }}>
                                                                <Typography>{extractFilenameFromPath(nestedKey)}</Typography>
                                                                <Typography sx={{ fontWeight: "bold" }}>{nestedValue}</Typography>
                                                                </div>
                                                            }
                                                            />
                                                        );
                                                        }
                                                    })}
                                                    </TreeItem>
                                                );
                                            }

                                            if (!key.startsWith("_")) {
                                                return (
                                                    <TreeItem
                                                    sx={{ borderLeft: "1px solid #d7d7d7", paddingY: 1, paddingX: 2 }}
                                                    itemId={sanitizeId(`${emailDate}_${hub}_${deliveryFacility}_${key}`)}
                                                    label={
                                                        <div
                                                        style={{ display: "flex", justifyContent: "space-between", gap: 3 }}
                                                        onClick={() => {
                                                            openFileViewer(key);
                                                        }}>
                                                        <Typography>{extractFilenameFromPath(key)}</Typography>
                                                        <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
                                                        </div>
                                                    }
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                      </TreeItem>
                                    );
                                })}
                              </TreeItem>
                            );
                        })}
                      </TreeItem>
                    ))}
                  </SimpleTreeView>
                  {openFile && (
                    <Box sx={{ height: "100%" }}>
                      {/* Dialog for JSON View */}
                      <EditableJsonDialog
                        openDialog={openFile}
                        handleCloseDialog={closeFileViewer}
                        jsonData={jsonData}
                        jsonDataId={jsonDataId}
                        handleSave={handleSaveJson}
                        fileSource={fileSource}
                        viewType="card"
                      />
                      <Box sx={{ height: "50px", opacity: 0 }}>placeholder</Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <Typography>No data available for this date</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
            {/* Popper for the calendar */}
            <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom" sx={{ zIndex: 1300 }}>
              <Box>
                <Calendar
                  onClickDay={handleCalendarDateChange}
                />
              </Box>
            </Popper>
          </Dialog>
          {/* Dialog for JSON View */}
          {/* <EditableJsonDialog openDialog={openFile} handleCloseDialog={closeFileViewer} jsonData={jsonData} jsonDataId={jsonDataId} handleSave={handleSaveJson} fileSource={fileSource} /> */}
        </Box>
      </LocalizationProvider>
    );
  };

  export default PpmodInsightsPage;