import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Box, Grid } from '@mui/material';
import { bolGetEditHistory, commonGetFileData } from '../Common/apiCalls';
import { base64toBlob, getMimeType } from '../Common/utils';
import { useNavigate } from 'react-router-dom';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
// import PdfViewer2 from './PdfViewer2';
import PdfViewer from './PdfViewer';

const HistoryViewerDialog = ({ openDialog, handleCloseDialog, jsonDataId, fileSource }) => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null); // Store the base64 file data
  const [fileType, setFileType] = useState(''); // Store the file type ('pdf' or 'image')
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(false); // Track errors
  const [historyData, setHistoryData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchBolHistory = async () => {
    setLoading(true);
    const apiResponse = await bolGetEditHistory(jsonDataId, handleAlert);
    if (apiResponse?.response_data) {
        setHistoryData(apiResponse?.response_data);
    }
    setLoading(false);
};

  useEffect(()=>{
    if(jsonDataId !== null && openDialog){
        fetchBolHistory();
    }
  }, [jsonDataId]);

  // Fetch file data when the dialog is opened
  const refreshFile = async () => {
    if (openDialog && fileSource) {
      setLoading(true);
      setError(false);

      // API call to fetch the base64 string for the file
      const apiResponse = await commonGetFileData(fileSource, handleAlert);
      if (apiResponse?.response_data) {
        let base64output = apiResponse.response_data;
        setFileData(base64output);
        setFileType(getMimeType(base64output));
        setPdfUrl(URL.createObjectURL(base64toBlob(base64output)))
      } else {
        setError(true);
        setFileData(null);
        setFileType(null);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshFile();
  }, [fileSource]); // Re-fetch when the dialog is opened or fileSource changes

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl); // Clean up URL when component unmounts or file changes
      }
    };
  }, [openDialog]);

  const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
    
    if (isLoggedOut) {
      sessionStorage.clear();
      sessionStorage.setItem("authToken", "__logged_out__");
      navigate("/");
    };
  };

  // Controls component to handle zoom in, zoom out, and reset
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls(); // Destructure zoom control functions
    return (
      <Box sx={{ display: 'flex', textAlign: 'center', marginBottom: '10px' }}>
        <Typography variant="body1" sx={{ marginRight: 2 }}>
          Image Controls:
        </Typography>
        <Button variant="contained" color="primary" onClick={() => zoomIn()} sx={{ marginRight: 2 }} size="small">
          <ZoomInRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => zoomOut()} sx={{ marginRight: 2 }} size="small">
          <ZoomOutRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => resetTransform()} size="small">
          <RestoreRoundedIcon />
        </Button>
      </Box>
    );
  };

  const renderHistoryChanges = (difference) => {
    return difference.map((item, index) => {
      const key = Object.keys(item)[0]; 
      const oldValue = item[key].old;
      const newValue = item[key].new;

      const renderValue = (value) => {
        if (typeof value === 'boolean') {
          return value ? 'True' : 'False'; // Display 'True' or 'False' for boolean values
        }
        return value || 'N/A'; // Fallback if the value is null or undefined
      };

      if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        return (
          <Box key={index} sx={{ marginBottom: 1 }}>
            <Typography variant="body">
              <strong>{key}:</strong>
            </Typography>
            {oldValue.map((oldObj, i) => {
              const newObj = newValue[i] || {}; // Ensure we have corresponding newObj, or empty object if not available
  
              return (
                <Box key={i} sx={{ marginBottom: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2">
                        <strong>Initial Value {i + 1}:</strong>
                        {Object.entries(oldObj).map(([field, value], index) => (
                          <Box key={index}>
                            <strong>{field.replace(/_/g, ' ')}:</strong> {renderValue(value) || 'N/A'}
                          </Box>
                        ))}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2">
                        <strong>New Value {i + 1}:</strong>
                        {Object.entries(newObj).map(([field, value], index) => (
                          <Box key={index}>
                            <strong>{field.replace(/_/g, ' ')}:</strong> {renderValue(value) || 'N/A'}
                          </Box>
                        ))}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      }

      return (
        <Box key={index} sx={{ marginBottom: 1 }}>
          <Typography variant="body">
            <strong>{key}:</strong>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2">
                <strong>Initial Value:</strong> {renderValue(oldValue)}
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2">
                <strong>New Value:</strong> {renderValue(newValue)}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    });
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle>Edited Data History</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left side - File Viewer */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <Typography>No file available</Typography>
              ) : fileData ? (
                fileType === 'pdf' ? (
                  <div>
                    <PdfViewer pdfUrl={pdfUrl} />
                  </div>
                ) : (
                  <TransformWrapper>
                    {/* Zoom Controls */}
                    <Controls />
                    <TransformComponent>
                      <img
                        src={`${fileData}`}
                        alt="File Content"
                        style={{
                          width: '100%',
                          maxHeight: '700px',
                          objectFit: 'contain',
                          cursor: 'pointer',
                        }}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                )
              ) : null}
            </Box>
          </Grid>

          {/* Right side - Edit History Display */}
          <Grid item xs={12} md={6}>
            {historyData.length > 0 ? (
              <Box sx={{ padding: 2 }}>
                {historyData.map((entry, index) => (
                  <>
                  <Box key={index} sx={{ display: 'flex', marginBottom: 2, justifyContent: 'space-between', marginTop: 5 }}>
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography variant="body" color="textSecondary">
                        <strong>User:</strong> {entry.username}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}> 
                      <Typography variant="body" color="textSecondary">
                        <strong>Time:</strong> {entry.inserted_datetime}
                      </Typography>
                    </Box>
                  </Box>
                  {renderHistoryChanges(entry.difference)}
                  </>
                ))}
              </Box>
            ) : (
              <Typography>No edit history available</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryViewerDialog;