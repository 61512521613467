import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Pages/Login';
import DashboardPage from './Pages/Dashboard';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          {/* Route for the login page */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />

          {/* Route for the home page (currently empty) */}
          <Route path="/home" element={<div>Home Page</div>} />
          <Route path="/dashboard" element={<DashboardPage/>}/>
        </Routes>
      </Router>
    </LocalizationProvider>
    
  );
}

export default App;
