// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-selected-day {
    background-color: rgb(26, 114, 238) !important; /* Green background for selected days */
    color: white;
    border-radius: 23%;
}

.calendar-unselected-day {
    background-color: transparent !important;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C,EAAE,uCAAuC;IACvF,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,wCAAwC;IACxC,YAAY;AAChB","sourcesContent":[".calendar-selected-day {\n    background-color: rgb(26, 114, 238) !important; /* Green background for selected days */\n    color: white;\n    border-radius: 23%;\n}\n\n.calendar-unselected-day {\n    background-color: transparent !important;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
