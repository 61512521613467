import axios from 'axios';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL;

// --- Login API ---

export const userLoginApi = async (userId, password) => {
    try {
        const response = await axios.post(`${BACKEND_BASE_URL}/api/user/login`, {user_id: userId, user_password: password}, {headers: {'Content-Type': 'application/json'}})
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}

// --- Get all BOL API ---

export const bolGetApi = async (filteredDate) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/get?emailDates=${filteredDate ? filteredDate : ""}`, {headers: headers});
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}

// --- Get File API ---

export const commonGetFileData = async (filepath) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/common/file/getData?filepath=${filepath}`, {headers: headers});
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}

// --- Get Summary API ---

export const bolGetSummaryApi = async (filteredDate) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/getSummary?emailDates=${filteredDate ? filteredDate : ""}`, {headers: headers});
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}

// --- Update Bol Doc API ---

export const bolUpdateApi = async (data, dataId) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.post(`${BACKEND_BASE_URL}/api/bol/update`, {data_extracted: data, bol_info_id: dataId}, {headers: headers})
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}

// --- Get Summary Tree API ---

export const bolGetSummaryTreeApi = async (filteredDate) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/getSummaryTree?emailDates=${filteredDate ? filteredDate : ""}`, {headers: headers});
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}