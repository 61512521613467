import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactCalendar from "react-calendar";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

//mui imports
import { DataGrid } from "@mui/x-data-grid";

import {
    Box,
    Typography,
    IconButton,
    CircularProgress,
    Tooltip,
    Button,
    TextField,
    Popper,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

//project imports
import FileViewer from "../Components/FileViewer";
import { bolGetApi, bolUpdateApi } from "../Common/apiCalls";
import { downloadCSV, extractFilenameFromPath } from "../Common/utils";
import EditableJsonDialog from "../Components/JsonEditer";
import ReusableSnackbar from "../Common/snackbar";
import FileAndDataViewerDialog from "../Components/ExtractedDataViewer";

import "./Dashboardv3.css";
import HistoryViewerDialog from "../Components/HistoryDialog";

const ReportsPage = () => {
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [jsonData, setJsonData] = useState(null);
    const [jsonDataId, setJsonDataId] = useState(null);
    const [fileSource, setFileSource] = useState("");
    const [allData, setAllData] = useState([]);

    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
    const [refreshDataGrid, setRefreshDataGrid] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [dataGridFilterModel, setDataGridFilterModel] = useState({ items: [] });  // State for DataGrid filter model
    const hubOptions = ["ORD", "LAX", "JFK", "DFW", "ATL"];
    const billTypeOptions = ["BOL-Outbound", "BOL-Inbound", "BOL-Outbound-Shein-PPMOD", "Unknown"];

    const handleFilterChange = (event, field) => {
        const selectedValue = event.target.value;

        // Clone filterModel to avoid direct mutation
        const updatedFilterModel = { ...filterModel };

        // Find if the filter exists already
        const existingFilterIndex = updatedFilterModel.items.findIndex(item => item.field === field);

        if (existingFilterIndex !== -1) {
            // If filter exists and the selected value is empty, remove it.  Otherwise, update the value.
            if (!selectedValue) {
                updatedFilterModel.items.splice(existingFilterIndex, 1);  // Remove the filter
            } else {
                updatedFilterModel.items[existingFilterIndex].value = selectedValue; // Update the value
            }
        } else {
            // If filter doesn't exist and a value is selected, add a new one
            if (selectedValue) {
                updatedFilterModel.items.push({ field, value: selectedValue });
            }
        }

        // Update the filterModel state
        setFilterModel(updatedFilterModel);
    };

    const combineFilters = (data, customFilters, dataGridFilters) => {
        let filteredData = [...data];  // Start with a copy of all data

        // Apply custom filters
        filteredData = filteredData.filter((row) => {
            return customFilters.every((filter) => {
                if (filter.field === "hub" && filter.value) {
                    return row.hub && row.hub.includes(filter.value);
                }
                if (filter.field === "bill_type" && filter.value) {
                    return row.bill_type === filter.value;
                }
                return true;
            });
        });

        // Apply DataGrid filters
        filteredData = filteredData.filter((row) => {
            return dataGridFilters.items.every(filter => {
                const { field, operator, value } = filter;
                const rowValue = row[field];  // Get the value from the row

                if (rowValue === undefined || rowValue === null) {
                    return false;  // Exclude if the value is missing
                }

                if (operator === 'contains') {
                    return String(rowValue).toLowerCase().includes(String(value).toLowerCase());
                }
                // Add more operators as needed (equals, greaterThan, lessThan, etc.)
                return true;  // Return true if the operator is not supported
            });
        });

        return filteredData;
    };


    useEffect(() => {
        const combinedFilteredData = combineFilters(allData, filterModel.items, dataGridFilterModel);
        setFilteredData(combinedFilteredData);
    }, [filterModel, dataGridFilterModel, allData]);  // Re-run when filters or data change

    useEffect(() => {
        const fetchAllBol = async () => {
            setIsLoading(true);
            const apiResponse = await bolGetApi(selectedDates.join(","), handleAlert);
            if (apiResponse?.response_data) {
                setAllData(apiResponse.response_data);
            }
            setIsLoading(false);
        };
        fetchAllBol();
    }, [refreshDataGrid]);

    const handleViewJson = (data, dataId, file_raw) => {
        setFileSource(file_raw);
        setJsonData(data);
        setJsonDataId(dataId);
        setOpenDialog(true);
    };

    const handleEditJson = (data, dataId, file_raw) => {
        setFileSource(file_raw);
        setJsonData(data);

        setJsonDataId(dataId);
        setOpenEditDialog(true);
    };

    const handleHistoryDialogOpen = (dataId, file_raw) => {
        setFileSource(file_raw);
        setJsonDataId(dataId);
        setOpenHistoryDialog(true);
    };

    const handleHistoryDialogClose = () => {
        setFileSource(null);
        setJsonDataId(null);
        setOpenHistoryDialog(false);
    };

    const handleCloseEditDialog = () => {
        setFileSource(null);
        setJsonData(null);
        setJsonDataId(null);
        setOpenEditDialog(false);
    };

    const handleSaveJson = async (jsonData, jsonDataId) => {
        // Call API and if API gives 202, then update allData
        setIsLoading(true);
        const apiResponseData = await bolUpdateApi(jsonData, jsonDataId, handleAlert);
        if (apiResponseData?.response_data) {
            let newData = allData;
            newData = newData.map((item) => (item.id == jsonDataId ? { ...item, data_extracted: jsonData } : item));
            setAllData(newData);
        }
        setIsLoading(false);
        setRefreshDataGrid((prev) => !prev);
    };

    const handleDownloadSummary = () => {
        downloadCSV(
            allData.filter((item) => typeof item.data_extracted === "object" && item.data_extracted !== null).map((item) => item.data_extracted),
            `summary-${selectedDates.join("_")}`
        );
    };

    const handleCloseDialog = () => {
        setFileSource(null);
        setJsonData(null);
        setJsonDataId(null);
        setOpenDialog(false);
    };

    const openFileViewer = (file_raw) => {
        setOpenFile(true);
        setFileSource(file_raw);
    };

    const closeFileViewer = () => {
        setOpenFile(false);
        // setFileSource(null);
    };

    const renderStatusIcon = (status) => {
        switch (status) {
            case "Processing":
                return (
                    <Tooltip title={status}>
                        <CircularProgress size={20} color="primary" />
                    </Tooltip>
                );
            case "Extracted":
                return (
                    <Tooltip title={status}>
                        <CheckCircleIcon style={{ color: "green" }} />
                    </Tooltip>
                );
            case "Failed":
                return (
                    <Tooltip title={status}>
                        <CloseIcon style={{ color: "red" }} />
                    </Tooltip>
                );
            case "Skipped":
                return (
                    <Tooltip title={status}>
                        <DoNotDisturbOnIcon style={{ color: "grey" }} />
                    </Tooltip>
                );
            case "Processing-Inbound":
                return (
                    <Tooltip title={status}>
                        <CircularProgress size={20} color="primary" />
                    </Tooltip>
                );
            case "Extracted-Inbound":
                return (
                    <Tooltip title={status}>
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                    </Tooltip>
                );
            default:
                return null;
        }
    };

    const columns = [
        { field: "email_date", headerName: "Email Date", width: 150 },
        {
            field: "file_raw",
            headerName: "Source file",
            width: 250,
            renderCell: (params) => (
                <Tooltip title="Click to view details">
                    <Typography
                        component="span"
                        sx={{
                            color: "blue",
                            cursor: "pointer",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                        onClick={() => openFileViewer(params.row.file_raw)}>
                        {extractFilenameFromPath(params.row.file_raw)}
                    </Typography>
                </Tooltip>
            ),
        },
        {
            field: "file_name",
            headerName: "Filename",
            width: 200,
        },
        {
            field: "email_subject",
            headerName: "Email Subject",
            width: 200,
        },
        {
            field: "file_status",
            headerName: "Status",
            width: 80,
            renderCell: (params) => <Box sx={{ width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>{renderStatusIcon(params.row.file_status)}</Box>,
        },
        {
            field: "data_extracted",
            headerName: "Output",
            width: 100,
            renderCell: (params) =>
                params.row.data_extracted ? (
                    <Box>
                        <Tooltip title="View">
                            <IconButton color="primary" onClick={() => handleViewJson(params.row.data_extracted, params.row.id, params.row.file_raw)}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton color="primary" onClick={() => handleEditJson(params.row.data_extracted, params.row.id, params.row.file_raw)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    <Typography>-</Typography>
                ),
        },
        { field: "bill_type", headerName: "BOL Type", width: 120 },
        {
            field: "hub",
            headerName: "Hub",
            width: 100,
        },
        { field: "remarks", headerName: "Remarks", width: 250 },
        {
            field: "history",
            headerName: "History",
            width: 80,
            renderCell: (params) =>
                params.row.bol_edited ? (
                    <Box>
                        <Tooltip title="View">
                            <IconButton color="primary" onClick={() => handleHistoryDialogOpen(params.row.id, params.row.file_raw)}>
                                <HistoryRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    null
                ),
        },
    ];

    const handleCalendarDateChange = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${String(date.getDate()).padStart(2, "0")}`;
        setSelectedDates((prev) => {
            if (prev.includes(dateStr)) {
                return prev.filter((insideDate) => insideDate !== dateStr);
            } else return [...prev, dateStr];
        });
    };

    const isDateSelected = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${String(date.getDate()).padStart(2, "0")}`;
        return selectedDates.includes(dateStr);
    };

    const showCalendar = (event) => {
        setCalendarAchor(event.currentTarget);
        setIsCalendarVisible((prev) => !prev);
    };

    const handleCloseCalendar = () => {
        setIsCalendarVisible(false);
    };

    const handleClearCalendarDates = () => {
        setSelectedDates([]);
        setIsCalendarVisible(false);
        setRefreshDataGrid((prev) => !prev);
    };

    const handleConfirmDates = () => {
        setRefreshDataGrid((prev) => !prev);
        setIsCalendarVisible(false);
    };

    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
        setShowAlert(true);
        setAlertMessage(alertMessage);
        setAlertSeverity(alertSeverity);

        if (isLoggedOut) {
            sessionStorage.clear();
            sessionStorage.setItem("authToken", "__logged_out__");
            navigate("/");
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <>
            {/* DataGrid */}
            <Box sx={{ marginTop: 5 }}>
                <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity} />
                <Box sx={{ marginBottom: 4, display: 'flex', flexDirection: 'row', marginBottom: 2, gap: 2 }}>
                    <TextField
                        label="Select email date"
                        variant="outlined"
                        width="20%"
                        InputProps={{
                            endAdornment: (
                                <>
                                    {!isCalendarVisible ? (
                                        <Tooltip title="Open Calendar">
                                            <IconButton color="primary" onClick={showCalendar}>
                                                <CalendarTodayIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Close Calendar">
                                            <IconButton color="primary" onClick={handleCloseCalendar}>
                                                <CloseRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Clear Selected Dates">
                                        <IconButton color="primary" onClick={handleClearCalendarDates}>
                                            <RefreshRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ),
                        }}
                    />
                    <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
                        <Box>
                            <Calendar
                                onClickDay={handleCalendarDateChange}
                                tileClassName={({ date }) => {
                                    return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day";
                                }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleConfirmDates} // This function will handle the date confirmation
                                >
                                    Confirm Dates
                                </Button>
                            </Box>
                        </Box>
                    </Popper>
                    {/* Filter Controls */}
                    <FormControl sx={{ width: "200px" }}>
                        <InputLabel>Hub</InputLabel>
                        <Select
                            value={filterModel?.items?.find(item => item.field === 'hub')?.value || ''}
                            onChange={(e) => handleFilterChange(e, 'hub')}
                            label="Hub"
                        >
                            <MenuItem value=""><em>None</em></MenuItem> {/* Add a "None" option */}
                            {hubOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: "200px" }}>
                        <InputLabel>BOL Type</InputLabel>
                        <Select
                            value={filterModel?.items?.find(item => item.field === 'bill_type')?.value || ''}
                            onChange={(e) => handleFilterChange(e, 'bill_type')}
                            label="BOL Type"
                        >
                             <MenuItem value=""><em>None</em></MenuItem>  {/* Add a "None" option */}
                            {billTypeOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    marginTop: 5,
                    width: '92vw',
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowx: 'hidden',
                }}>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: "center", overflow: 'auto' }}>
                        {isLoading ? (
                            <>
                                <CircularProgress />
                            </>
                        ) : (
                            <>
                                <DataGrid
                                    rows={filteredData} // Use filteredData as default
                                    columns={columns}
                                    disableColumnFilter={false}
                                    pagination={false}
                                    getRowClassName={(params) => {
                                        // Check if bol_edited is true or false
                                        return params?.row?.bol_edited ? 'row-edited' : 'row-not-edited';
                                    }}
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                          fontWeight: "bold",
                                        },
                                        width: '60%', 
                                        height: '90%'
                                    }}
                                    filterModel={dataGridFilterModel}
                                    onFilterModelChange={(newFilterModel) => setDataGridFilterModel(newFilterModel)} // Capture DataGrid filters
                                />
                            </>
                        )}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <Box></Box>

                    {/* Download Summary Button */}
                    <Tooltip title="Download Consolidated Summary">
                        <IconButton
                            onClick={handleDownloadSummary}
                            color="primary"
                            disabled={!allData || allData.length === 0}
                        >
                            <DownloadForOfflineIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {/* FileViewer Dialog */}
            <FileViewer open={openFile} onClose={closeFileViewer} fileSource={fileSource} />

            {/* Dialog for JSON View */}
            <FileAndDataViewerDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} fileSource={fileSource} jsonData={jsonData} />

            {/* Dialog for Edit Json View */}
            <EditableJsonDialog
                openDialog={openEditDialog}
                handleCloseDialog={handleCloseEditDialog}
                jsonData={jsonData}
                jsonDataId={jsonDataId}
                handleSave={handleSaveJson}
                fileSource={fileSource}
                viewType="Dialog"
            />

            {/* Dialog for View History */}
            <HistoryViewerDialog
                openDialog={openHistoryDialog}
                handleCloseDialog={handleHistoryDialogClose}
                jsonDataId={jsonDataId}
                fileSource={fileSource}
            />
        </>
    );
};

export default ReportsPage;