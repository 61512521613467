export const getMimeType = (base64String) => {
    // Check if the base64 string contains a valid data URL prefix
    const mimeTypeMatch = base64String.match(/^data:([a-zA-Z0-9-+\/]+);base64,/);
    
    if (mimeTypeMatch) {
      const mimeType = mimeTypeMatch[1];
      if (mimeType.startsWith('image')) {
        return "image"
      } else if (mimeType === 'application/pdf') {
        return "pdf"
      } else {
        return "other"
      }
    } else {
      return null
    }
  };

export const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};


export const extractFilenameFromPath = (filepath) => {
    return filepath.split('/').pop();
}

export const convertToCSV = (jsonData) => {
    const header = Object.keys(jsonData[0]);
    const rows = jsonData.map(item =>
        header.map(fieldName => JSON.stringify(item[fieldName], (key, value) => value === null ? '' : value)).join(',')
    );
    return [header.join(','), ...rows].join('\r\n');
};

// Function to trigger CSV file download
export const downloadCSV = (jsonData, filename) => {
    const csv = convertToCSV(jsonData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}.csv`);
    link.click();
};