import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

const EditableJsonDialog = ({ openDialog, handleCloseDialog, jsonData, jsonDataId, handleSave }) => {
  const [editableJsonData, setEditableJsonData] = useState({});

  // Initialize the editable jsonData when the dialog opens
  useEffect(() => {
    if (jsonData) {
      setEditableJsonData({ ...jsonData });
    }
  }, [jsonData]);

  // Handle value changes in the form
  const handleValueChange = (key, value) => {
    setEditableJsonData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  // Render editable form elements based on the type of value
  const renderEditableField = (key, value) => {
    if (typeof value === 'string') {
      return (
        <TextField
          key={key}
          label={key}
          value={value}
          onChange={(e) => handleValueChange(key, e.target.value)}
          fullWidth
          variant='outlined'
        />
      );
    }

    if (typeof value === 'number') {
      return (
        <TextField
          key={key}
          label={key}
          type="number"
          value={value}
          onChange={(e) => handleValueChange(key, parseFloat(e.target.value))}
          fullWidth
          variant='outlined'
        />
      );
    }

    if (typeof value === 'boolean') {
      return (
        <FormControlLabel
          key={key}
          control={<Checkbox checked={value} onChange={(e) => handleValueChange(key, e.target.checked)} />}
          label={key}
          variant='outlined'
        />
      );
    }

    return (
        <TextField
          key={key}
          label={key}
          value={value}
          onChange={(e) => handleValueChange(key, e.target.value)}
          fullWidth
          variant='outlined'
        />
      );;
  };

  const handleSaveChanges = () => {
    // Pass the updated jsonData back through the handleSave prop
    handleSave(editableJsonData, jsonDataId);
    handleCloseDialog();
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Edit Extracted Output</DialogTitle>
      <DialogContent>
        {editableJsonData && (
          <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {Object.keys(editableJsonData).map((key) => {
              const value = editableJsonData[key];
              return (<Box sx={{marginY: 2, width: 600}}>{renderEditableField(key, value)}</Box>);
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
        <Button onClick={handleSaveChanges} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditableJsonDialog;
