import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography } from '@mui/material';
import { commonGetFileData } from '../Common/apiCalls';
import { base64toBlob, getMimeType } from '../Common/utils';
import PdfViewer from './PdfViewer';

const FileViewer = ({ open, onClose, fileSource }) => {
  const [fileData, setFileData] = useState(null); // Store the base64 file data
  const [fileType, setFileType] = useState(''); // Store the file type ('pdf' or 'image')
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(false); // Track errors

  // Fetch file data when the dialog is opened

    const refreshFile = async () => {
        if (open && fileSource) {
            setLoading(true);
            setError(false);
      
            // API call to fetch the base64 string for the file
            const apiResponse = await commonGetFileData(fileSource);
            if (apiResponse?.response_data) {
              let base64output = apiResponse.response_data;
              setFileData(base64output);
              setFileType(getMimeType(base64output));
            } else {
              setError(true);
              setFileData(null);
              setFileType(null);
            }
            setLoading(false);
          }
    };

    useEffect(() => {
        refreshFile();
    }, [fileSource]); // Re-fetch when the dialog is opened or fileSource changes

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>File Viewer</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography>No file available</Typography>
        ) : fileData ? (
          fileType === 'pdf' ? (
            <div>
                <PdfViewer pdfUrl={URL.createObjectURL(base64toBlob(fileData))}/>
              {/* <Document file={URL.createObjectURL(base64toBlob(fileData))}>
                <Page pageNumber={1} />
              </Document> */}
            </div>
          ) : (
            <img
              src={`${fileData}`}
              alt="File Content"
              style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
            />
          )
        ) : fileType === "image" ? (
          <Typography>No file available</Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileViewer;
