import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box } from "@mui/material";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const labels = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
const fullDayNames = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thur: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
};

const splitIntoGroups = (arr, groupSize) => Array.from({ length: Math.ceil(arr.length / groupSize) }, (_, i) => arr.slice(i * groupSize, (i + 1) * groupSize));

const ChartjsBarChart = ({ chartDetailData, dates, weekNumber = 0, selectedHub = "TTL" }) => {
    const uniqueDateArray = [...new Set(dates)];
    const [datesInGroupOfSeven, setDatesInGroupOfSeven] = useState([]);
    const [chartData, setChartData] = useState([0, 0, 0, 0, 0, 0, 0]);

    useEffect(() => {
        const groupedDates = splitIntoGroups(uniqueDateArray, 7);
        if (JSON.stringify(groupedDates) !== JSON.stringify(datesInGroupOfSeven)) {
            setDatesInGroupOfSeven(groupedDates);
        }
    }, [uniqueDateArray, datesInGroupOfSeven]);

    useEffect(() => {
        if (datesInGroupOfSeven.length > 0) {
            const updatedChartData = datesInGroupOfSeven[weekNumber]?.map((date) => {
                return chartDetailData[date]?.[selectedHub]?.pallet || 0;
            });
            setChartData((prevData) => (updatedChartData ? updatedChartData : prevData));
        }
    }, [datesInGroupOfSeven, selectedHub, chartDetailData, weekNumber]);

    const weeklyTotal = chartData.reduce((acc, value) => acc + value, 0);
  // Add a "gap" category before "Total"
  const chartDataWithTotal = [...chartData, 0, weeklyTotal]; // 0 for the gap
  const labelsWithTotal = [...labels, "", "Total"]; // Empty label for the gap
    const barColors = chartDataWithTotal.map((_, index) =>
    index === 8 ? "#9370db" : index === 7 ? "transparent" : "#0093fb" // Transparent for gap
    );

    const data = {
        labels: labelsWithTotal,
        datasets: [
            {
                label: "Dataset 1",
                data: chartDataWithTotal,
                backgroundColor: barColors,
                borderColor: "#ffffff",
                borderWidth: 1,
                categoryPercentage: 1.0,
                barPercentage: 1.0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 3,
            },
        },
        interaction: {
            mode: "index",
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    padding: -4,
                    callback: (value, index) => {
                        return index === 7 ? "" : labelsWithTotal[index];
                    },
                },
            },
            y: {
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                    beginAtZero: true,
                },
                border: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart",
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const index = tooltipItems[0]?.dataIndex;
                        const shortLabel = labelsWithTotal[index];
                        const dateLabel = datesInGroupOfSeven?.[weekNumber][index];
                        if (index === 8) return "Weekly Total";
                        if (index === 7) return "";
                        return `${dateLabel} (${fullDayNames[shortLabel]})` || shortLabel;
                    },
                    label: (tooltipItem) => {
                        const value = tooltipItem.raw;
                        const index = tooltipItem.dataIndex;
                        if (index === 8) return `# Weekly Total: ${value}`;
                        if (index === 7) return "";
                        return `# Pallet: ${value}`;
                    },
                },
                filter: (tooltipItem) => tooltipItem.dataIndex !== 7,
            },
            datalabels: {
                display: true,
                color: "black",
                formatter: (value, context) => {
                    if (context.dataIndex === 7) return "";
                    return value?.toLocaleString("en-US");
                },
                offset: -7,
                anchor: "end",
                align: "end",
                font: {
                    size: 9,
                    weight: "bold",
                },
            },
        },
    };

    return (
        <Box sx={{ pt: 1.25, height: 71, width: 350, display: weekNumber !== 5 ? "block" : uniqueDateArray.length > 35 ? "block" : "none" }}>
            <Bar options={options} data={data} />
        </Box>
    );
};

export default ChartjsBarChart;