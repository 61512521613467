import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactCalendar from "react-calendar";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

//mui imports
import { axisClasses, BarChart, PieChart } from "@mui/x-charts";

import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Popper,
} from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

//project imports
import FileViewer from "../Components/FileViewer";
import { bolGetSummaryApi } from "../Common/apiCalls";
import ReusableSnackbar from "../Common/snackbar";

import "./Dashboardv3.css";

const SummaryPage = () => {
    const navigate = useNavigate();
  
    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  
    const [isLoading, setIsLoading] = useState(false);
  
    const [allSummaryData, setAllSummaryData] = useState([]);
  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
  
    const transformJsonToListForPie = (data) => {
      const transformedData = Object.entries(data).map((item, index) => {
        const [key, value] = item;
        return { id: index, value: value, label: key };
      });
      return transformedData;
    };
  
    const transformJsonToListForBar = (data) => {
      const transformedData = Object.entries(data).map((item, index) => {
        const [key, value] = item;
        return { id: index, date: key, ...value };
      });
      return transformedData;
    };
  
    useEffect(() => {
      const fetchAllBolSummary = async () => {
        setIsLoading(true);
        const apiResponse = await bolGetSummaryApi(selectedDates.join(","), handleAlert);
        if (apiResponse?.response_data) {
          setAllSummaryData(apiResponse.response_data);
        }
        setIsLoading(false);
      };
      fetchAllBolSummary();
    }, [selectedDates]);
  
    const handleCalendarDateChange = (date) => {
      const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${String(date.getDate()).padStart(2, "0")}`;
      setSelectedDates((prev) => {
        if (prev.includes(dateStr)) {
          return prev.filter((insideDate) => insideDate !== dateStr);
        } else return [...prev, dateStr];
      });
    };
  
    const isDateSelected = (date) => {
      const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${String(date.getDate()).padStart(2, "0")}`;
      return selectedDates.includes(dateStr);
    };
  
    const showCalendar = (event) => {
      setCalendarAchor(event.currentTarget);
      setIsCalendarVisible((prev) => !prev);
    };
  
    const barChartSetting = {
      yAxis: [
        {
          label: "# of Pallets",
        },
      ],
      width: 1000,
      height: 500,
      sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: "translate(-20px, 0)",
        },
      },
    };
  
    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertSeverity(alertSeverity);
  
      if (isLoggedOut) {
        sessionStorage.clear();
        sessionStorage.setItem("authToken", "__logged_out__");
        navigate("/");
      }
    };
  
    const handleCloseAlert = () => {
      setShowAlert(false);
    };
  
    return (
      <Box sx={{ marginTop: 5 }}>
        <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity} />
        <Box sx={{ marginBottom: 4 }}>
          <TextField
            label="Select date"
            variant="outlined"
            width="20%"
            InputProps={{
              endAdornment: (
                <IconButton color="primary" onClick={showCalendar}>
                  <CalendarTodayIcon />
                </IconButton>
              ),
            }}
          />
          <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
            <Calendar
              onClickDay={handleCalendarDateChange}
              tileClassName={({ date }) => {
                return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day";
              }}
            />
          </Popper>
        </Box>
  
        {/* {Object.entries(allSummaryData).map(([emailDate, item], index) => (
                      <Box sx={{display: "flex", flexDirection: "column", border: "1px solid black", width: "30%", height: "300px", alignItems: "center"}}>
                          <Typography>{emailDate}</Typography>
                          <PieChart series={[{data: transformJsonToListForPie(item)}]} width={200} height={200}/>
                      </Box>
                  ))} */}
        <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          {isLoading ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <BarChart
                dataset={transformJsonToListForBar(allSummaryData)}
                xAxis={[{ scaleType: "band", dataKey: "date" }]}
                series={[
                  { dataKey: "JFK", label: "JFK" },
                  { dataKey: "ORD", label: "ORD" },
                  { dataKey: "LAX", label: "LAX" },
                  { dataKey: "DFW", label: "DFW" },
                  { dataKey: "ATL", label: "ATL" },
                  { dataKey: "NA", label: "NA" },
                ]}
                {...barChartSetting}
              />
            </>
          )}
        </Box>
      </Box>
    );
  };

  export default SummaryPage;