import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-calendar/dist/Calendar.css";

import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Drawer, 
  List, 
  ListItem, 
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuIcon from '@mui/icons-material/Menu';
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CloseIcon from "@mui/icons-material/Close";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import EditIcon from "@mui/icons-material/Edit";
// import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
// import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//project imports
// import FileViewer from "../Components/FileViewer";
// import { bolGetApi, bolGetSummaryApi, bolGetSummaryTreeApi, bolUpdateApi, bolGetMonthlySummaryApi, bolGetExtractedFileData } from "../Common/apiCalls";
// import { downloadCSV, extractFilenameFromPath } from "../Common/utils";
// import TabPanel from "../Components/TabPanel";
// import EditableJsonDialog from "../Components/JsonEditer";
// import ReusableSnackbar from "../Common/snackbar";
// import FileAndDataViewerDialog from "../Components/ExtractedDataViewer";

import "./Dashboardv3.css";
import InboundInsightsPage from "./InboundInsights";
import OutboundInsightsPage from "./OutboundInsights";
import ReportsPage from "./Reports";
import SummaryPage from "./SummaryPage";
import PpmodInsightsPage from "./PpmodInsights";

const DashboardPagev3 = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [inboundOpen, setInboundOpen] = useState(false);
  const [outboundOpen, setOutboundOpen] = useState(false);
  const [outboundSaasOpen, setOutboundSaasOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [othersOpen, setOthersOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (index) => {
    setActiveTab(index);
    setOpen(false); // Close the Drawer after selecting a tab
  };

  const handleToggleCollapse = (menuType) => {
    if (menuType === "inbound") {
      setInboundOpen(!inboundOpen);
    } else if (menuType === "outbound") {
      setOutboundOpen(!outboundOpen);
    } else if (menuType === "others") {
      setOthersOpen(!othersOpen);
    } else if (menuType === "saas-outbound") {
      setOutboundSaasOpen(!outboundSaasOpen);
    } 
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* Side Drawer */}
      <Drawer
        sx={{
          width: 400,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 400,
            boxSizing: "border-box",
            paddingTop: 4,
            paddingLeft: 2,
            paddingRight: 2
          },
        }}
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        {/* Drawer Heading */}
        <Box sx={{ paddingLeft: 2, paddingBottom: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            BOL Automation
          </Typography>
        </Box>

        <Divider sx={{ marginBottom: 2 }} />

        <List>
          {/* Inbound */}
          <ListItem
            button
            onClick={(e) => {
              // Only handle click if not disabled
              handleToggleCollapse("inbound");
              // if (!isDisabled) {
              //   handleToggleCollapse("inbound");
              // }
            }}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "8px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              marginBottom: 1,
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
              // opacity: isDisabled ? 0.5 : 1,
              // cursor: isDisabled ? "not-allowed" : "pointer", 
            }}
          >
            <ListItemText primary="Inbound" />
            {inboundOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={inboundOpen} timeout="auto" unmountOnExit>
            <List sx={{ paddingLeft: 4 }}>
              <ListItem
                button
                onClick={() => handleMenuItemClick(2)}
                sx={{
                  paddingLeft: 2,
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  marginBottom: 1,
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <ListItemText primary="Regular" />
              </ListItem>
            </List>
          </Collapse>

          {/* Outbound */}
          <ListItem
            button
            onClick={() => handleToggleCollapse("outbound")}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "8px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              marginBottom: 1,
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Outbound" />
            {outboundOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={outboundOpen} timeout="auto" unmountOnExit>
            <List sx={{ paddingLeft: 4 }}>
              <ListItem
                button
                onClick={() => handleMenuItemClick(0)}
                sx={{
                  paddingLeft: 2,
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  marginBottom: 1,
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <ListItemText primary="Regular" />
              </ListItem>
              <ListItem
                button
                onClick={() => handleToggleCollapse("saas-outbound")}
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  marginBottom: 1,
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <ListItemText primary="Sortation (SaaS)" />
                {outboundSaasOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
              <Collapse in={outboundSaasOpen} timeout="auto" unmountOnExit>
                <List sx={{ paddingLeft: 4 }}>
                  <ListItem
                    button
                    onClick={() => handleMenuItemClick(3)}
                    sx={{
                      paddingLeft: 2,
                      borderRadius: "8px",
                      border: "1px solid #ddd",
                      backgroundColor: "#f9f9f9",
                      marginBottom: 1,
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  >
                    <ListItemText primary="Shein PPMOD" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Collapse>

          {/* All Bol View page (Reports) */}
          <ListItem
            button
            onClick={() => handleMenuItemClick(1)}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "8px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              marginBottom: 1,
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
              // opacity: isDisabled ? 0.5 : 1,
              // cursor: isDisabled ? "not-allowed" : "pointer", 
            }}
          >
            <ListItemText primary="View All BOLs" />
          </ListItem>

          {/* Others */}
          <ListItem
            button
            onClick={(e) => {
              // Only handle click if not disabled
              if (!isDisabled) {
                handleToggleCollapse("others");
              }
            }}
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "8px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              marginBottom: 1,
              "&:hover": {
                backgroundColor: isDisabled ? "#f9f9f9" : "#e0e0e0",
              },
              opacity: isDisabled ? 0.5 : 1,
              cursor: isDisabled ? "not-allowed" : "pointer",
            }}
            disabled={isDisabled}
          >
            <ListItemText primary="Others" />
            {othersOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={othersOpen} timeout="auto" unmountOnExit>
            <List sx={{ paddingLeft: 4 }}>
              <ListItem
                button
                onClick={() => handleMenuItemClick(1)}
                sx={{
                  paddingLeft: 2,
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  marginBottom: 1,
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <ListItemText primary="Reports" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, paddingX: 5 }}>
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: 40,
            paddingY: 3,
          }}
        >
          <Tooltip title="Menu">
            <IconButton color="primary" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <Typography variant="h4" sx={{ marginLeft: 2 }}>
            {activeTab === 0 ? "Outbound Insights" : activeTab === 1 ? "View BOLs" : activeTab === 2 ? "Inbound Insights" : activeTab === 3 ? "Shein PPMOD Insights" : "Dashboard"}
          </Typography>

          <Tooltip title="Logout">
            <IconButton color="primary" onClick={handleLogout} sx={{ marginLeft: "auto" }}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <hr />

        {/* Main Content Panel */}
        <Box sx={{ width: "100%", paddingTop: "50px" }}>
          {activeTab === 0 && <OutboundInsightsPage />} 
          {activeTab === 1 && <ReportsPage />}
          {activeTab === 2 && <InboundInsightsPage />}
          {activeTab === 3 && <PpmodInsightsPage/>}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPagev3;

