import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, CircularProgress, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Tabs, Tab, Popper } from '@mui/material';
// import { CheckCircle, Close, Visibility, DashCircle } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { DataGrid } from '@mui/x-data-grid';
import FileViewer from '../Components/FileViewer';
import { bolGetApi, bolGetSummaryApi, bolGetSummaryTreeApi, bolUpdateApi } from '../Common/apiCalls';
import { downloadCSV, extractFilenameFromPath } from '../Common/utils';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useNavigate } from 'react-router-dom';
import TabPanel from '../Components/TabPanel';
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";
import "./Dashboard.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import { PieChart } from '@mui/icons-material';
import { axisClasses, BarChart, PieChart } from '@mui/x-charts';
import EditableJsonDialog from '../Components/JsonEditer';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

const SummaryPage = () => {

    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [allSummaryData, setAllSummaryData] = useState([]);

    const transformJsonToListForPie = (data) => {
        
        const transformedData =  Object.entries(data).map((item, index) => {
            const [key, value] = item;
            return {id: index, value: value, label: key};
        });
        console.log(transformedData);
        return transformedData;

    };

    const transformJsonToListForBar = (data) => {
        const transformedData =  Object.entries(data).map((item, index) => {
            const [key, value] = item;
            return {id: index, date: key, ...value};
        });
        console.log(transformedData);
        return transformedData;
    }

    useEffect(()=>{
        const fetchAllBolSummary = async () => {
            setIsLoading(true);
            const apiResponse = await bolGetSummaryApi(selectedDates.join(","));
            if (apiResponse?.response_data) {
                setAllSummaryData(apiResponse.response_data);
            }
            setIsLoading(false);
        };
        fetchAllBolSummary();
        }, [selectedDates]);

        const handleCalendarDateChange = (date) => {
            const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
            setSelectedDates((prev) => {
                if (prev.includes(dateStr)) {return prev.filter((insideDate) => insideDate !== dateStr)}
                else return [...prev, dateStr]
            });
        };
    
        const isDateSelected = (date) => {
            const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
            return selectedDates.includes(dateStr);
        };
    
        const showCalendar = (event) => {
            setCalendarAchor(event.currentTarget);
            setIsCalendarVisible(prev => !prev);
        };

        const barChartSetting = {
            yAxis: [
              {
                label: '# of Pallets',
              },
            ],
            width: 1000,
            height: 500,
            sx: {
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
              },
            },
          };
          



    return (
        <Box sx={{marginTop: 5}}>
            <Box sx={{marginBottom: 4}}>
                <TextField label="Select date" variant="outlined" width="20%"
                        InputProps={{endAdornment: (<IconButton color="primary" onClick={showCalendar} ><CalendarTodayIcon/></IconButton>)}}/>
                <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
                    <Calendar 
                        onClickDay={handleCalendarDateChange}
                        tileClassName={( {date} ) => {return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day"}}
                    />
                </Popper>
                
            </Box>

            {/* {Object.entries(allSummaryData).map(([emailDate, item], index) => (
                    <Box sx={{display: "flex", flexDirection: "column", border: "1px solid black", width: "30%", height: "300px", alignItems: "center"}}>
                        <Typography>{emailDate}</Typography>
                        <PieChart series={[{data: transformJsonToListForPie(item)}]} width={200} height={200}/>
                    </Box>
                ))} */}
            <Box sx={{display: "flex", gap: 2, justifyContent: "center"}}>
            {isLoading ? <><CircularProgress/></> : 
                
                <><BarChart dataset={transformJsonToListForBar(allSummaryData)} 
                            xAxis={[{scaleType: "band", dataKey: "date"}]}
                            series={[
                                {dataKey: "JFK", label: "JFK"},
                                {dataKey: "ORD", label: "ORD"},
                                {dataKey: "LAX", label: "LAX"},
                                {dataKey: "DFW", label: "DFW"},
                                {dataKey: "ATL", label: "ATL"},
                                {dataKey: "NA", label: "NA"},
                            ]}
                            {...barChartSetting}
                /></>
            }
            </Box>
            


        </Box>
    )
}


const ReportsPage = () => {


    const [openDialog, setOpenDialog] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [jsonData, setJsonData] = useState(null);
    const [jsonDataId, setJsonDataId] = useState(null);
    const [fileSource, setFileSource] = useState('');
    const [allData, setAllData] = useState([]);

    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [refreshDataGrid, setRefreshDataGrid] = useState(false);


    useEffect(()=>{
    const fetchAllBol = async () => {
        setIsLoading(true);
        const apiResponse = await bolGetApi(selectedDates.join(","));
        if (apiResponse?.response_data) {
            setAllData(apiResponse.response_data);
        }
        setIsLoading(false);
    };
    fetchAllBol();
    }, [selectedDates, refreshDataGrid]);

    const handleViewJson = (data, dataId) => {
        setJsonData(data);
        setJsonDataId(dataId);
        setOpenDialog(true);
    };

    const handleEditJson = (data, dataId) => {
        setJsonData(data);
        setJsonDataId(dataId);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setJsonData(null);
        setJsonDataId(null);
        setOpenEditDialog(false);
    };

    const handleSaveJson = async (jsonData, jsonDataId) => {
        // Call API and if API gives 202, then update allData
        setIsLoading(true);
        const apiResponseData= await bolUpdateApi(jsonData, jsonDataId);
        if (apiResponseData.response_data) {
            let newData = allData;
            newData = newData.map(item => item.id == jsonDataId ? {...item, data_extracted: jsonData} : item);
            setAllData(newData);
        }
        setIsLoading(false);
        setRefreshDataGrid(prev => !prev);
    }


    const handleDownloadSummary = () => {
    downloadCSV(allData.filter(item => typeof item.data_extracted === 'object' && item.data_extracted !== null).map(item => item.data_extracted), `summary-${selectedDates.join("_")}`);
    }

    const handleCloseDialog = () => {
        setJsonData(null);
        setJsonDataId(null);
        setOpenDialog(false);
    };

    const openFileViewer = (file_raw) => {
    setOpenFile(true);
    setFileSource(file_raw);
    };

    const closeFileViewer = () => {
    setOpenFile(false);
    setFileSource(null);
    };

    const renderStatusIcon = (status) => {
    switch (status) {
        case 'Processing':
        return <Tooltip title={status}><CircularProgress size={20} color="primary" /></Tooltip>;
        case 'Extracted':
        return <Tooltip title={status}><CheckCircleIcon style={{ color: 'green' }} /></Tooltip>;
        case 'Failed':
        return <Tooltip title={status}><CloseIcon style={{ color: 'red' }} /></Tooltip>;
        case 'Skipped':
        return <Tooltip title={status}><DoNotDisturbOnIcon style={{ color: 'grey' }} /></Tooltip>;
        default:
        return null;
    }
    };

    const columns = [
    { field: 'email_date', headerName: 'Email Date', width: 250 },
    {
        field: 'file_raw',
        headerName: 'Source file',
        width: 300,
        renderCell: (params) => (
        <Tooltip title="Click to view details">
            <Typography
            component="span"
            sx={{
                color: 'blue',
                cursor: 'pointer',
                '&:hover': {
                textDecoration: 'underline',
                },
            }}
            onClick={() => openFileViewer(params.row.file_raw)}

            >
            {extractFilenameFromPath(params.row.file_raw)}
            </Typography>
        </Tooltip>
        ),
    },
    {
        field: "file_name", headerName: 'Filename', width: 200
    },
    {
        field: 'file_status',
        headerName: 'Status',
        width: 120,
        renderCell: (params) => <Box sx={{width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center"}}>{renderStatusIcon(params.row.file_status)}</Box>,
    },
    {
        field: 'data_extracted',
        headerName: 'Output',
        width: 100,
        renderCell: (params) => (
        params.row.data_extracted ? (
            <Box>
                <Tooltip title="View"><IconButton color="primary" onClick={() => handleViewJson(params.row.data_extracted, params.row.id)}><VisibilityIcon /></IconButton></Tooltip>
                <Tooltip title="Edit"><IconButton color="primary" onClick={() => handleEditJson(params.row.data_extracted, params.row.id)}><EditIcon/></IconButton></Tooltip>
            </Box>
            
        ) : (
            <Typography>-</Typography>
        )
        ),
    },
    { field: 'bill_type', headerName: 'Bill Type', width: 150 },
    { field: "hub", headerName: "Hub", width: 100},
    { field: "remarks", headerName: "Remarks", width: 200}
    ];

    const handleCalendarDateChange = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
        setSelectedDates((prev) => {
            if (prev.includes(dateStr)) {return prev.filter((insideDate) => insideDate !== dateStr)}
            else return [...prev, dateStr]
        });
    };

    const isDateSelected = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
        return selectedDates.includes(dateStr);
    };

    const showCalendar = (event) => {
        setCalendarAchor(event.currentTarget);
        setIsCalendarVisible(prev => !prev);
    }

    return (
    <>

        {/* DataGrid */}
        <Box sx={{marginTop: 5}}>
        <Box sx={{marginBottom: 4}}>
            <TextField label="Select date" variant="outlined" width="20%"
                    InputProps={{endAdornment: (<IconButton color="primary" onClick={showCalendar} ><CalendarTodayIcon/></IconButton>)}}/>
            <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
                <Calendar 
                    onClickDay={handleCalendarDateChange}
                    tileClassName={( {date} ) => {return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day"}}
                />
            </Popper>
            
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", height: "500px", width: '100%' }}>
            {isLoading ? <><CircularProgress/></> : <><DataGrid rows={allData} columns={columns} disableColumnFilter={false} pagination={false} /></>}            
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

            <Box></Box>

            {/* Download Summary Button */}
            <Tooltip title="Download Consolidated Summary">
            <IconButton
                onClick={handleDownloadSummary}
                color="primary"
            >
                <DownloadForOfflineIcon />
            </IconButton>
            </Tooltip>
        </Box>
        </Box>

        {/* FileViewer Dialog */}
        <FileViewer open={openFile} onClose={closeFileViewer} fileSource={fileSource} />

        {/* Dialog for JSON View */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Extracted Output</DialogTitle>
        <DialogContent>
            {jsonData && (
            <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                {Object.keys(jsonData).map((key, idx) => (
                <Typography key={idx}>
                    <strong>{key}:</strong> {JSON.stringify(jsonData[key])}
                </Typography>
                ))}
            </Box>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
        </Dialog>

        {/* Dialog for Edit Json View */}
        <EditableJsonDialog 
        openDialog={openEditDialog}
        handleCloseDialog={handleCloseEditDialog}
        jsonData={jsonData}
        jsonDataId={jsonDataId}
        handleSave={handleSaveJson}/>
    </>
    );
};

const InsightsPage = () => {

    const [allSummaryTreeData, setAllSummaryTreeData] = useState({});

    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const [openFile, setOpenFile] = useState(false);
    const [fileSource, setFileSource] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleCalendarDateChange = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
        setSelectedDates((prev) => {
            if (prev.includes(dateStr)) {return prev.filter((insideDate) => insideDate !== dateStr)}
            else return [...prev, dateStr]
        });
    };

    const isDateSelected = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
        return selectedDates.includes(dateStr);
    };

    const showCalendar = (event) => {
        setCalendarAchor(event.currentTarget);
        setIsCalendarVisible(prev => !prev);
    };

    useEffect(() => {
        const fetchAllBolSummaryTree = async () => {
            setIsLoading(true);
            const apiResponse = await bolGetSummaryTreeApi(selectedDates.join(","));
            if (apiResponse?.response_data) {
                setAllSummaryTreeData(apiResponse.response_data);
            }
            setIsLoading(false);
        };
        fetchAllBolSummaryTree();
    }, [selectedDates]);

    const openFileViewer = (file_raw) => {
        setOpenFile(true);
        setFileSource(file_raw);
        };
    
    const closeFileViewer = () => {
        setOpenFile(false);
        setFileSource(null);
    };

    return (
        <>
            <Box sx={{marginTop: 5}}>
                <Box sx={{marginBottom: 4}}>
                    <TextField label="Select date" variant="outlined" width="20%"
                            InputProps={{endAdornment: (<IconButton color="primary" onClick={showCalendar} ><CalendarTodayIcon/></IconButton>)}}/>
                    <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
                        <Calendar 
                            onClickDay={handleCalendarDateChange}
                            tileClassName={( {date} ) => {return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day"}}
                        />
                    </Popper>
                    
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2}}>
                {isLoading ? <><CircularProgress/></> : (<>
                    {Object.entries(allSummaryTreeData).map(([emailDate, treeSummary]) => (
                        <SimpleTreeView sx={{width: 700}}>
                            <TreeItem sx={{backgroundColor: "#5c9fff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2}}
                             itemId={emailDate} label={<div style={{display: "flex", justifyContent: "space-between"}}><Typography>{emailDate}</Typography><Typography>{treeSummary?._total}</Typography></div>}>
                                {Object.entries(treeSummary).map(([hub, hubSummary]) => {
                                    if (!hub.startsWith("_")) return (<TreeItem sx={{backgroundColor: "#80b3ff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2}}
                                                                                 itemId={`${emailDate}_${hub}`} label={<div style={{display: "flex", justifyContent: "space-between"}}><Typography>{hub}</Typography><Typography>{hubSummary?._total}</Typography></div>}>
                                        {Object.entries(hubSummary).map(([deliveryFacility, deliveryFacilitySummary]) => {
                                            if (!deliveryFacility.startsWith("_")) return (<TreeItem sx={{backgroundColor: "#99ccff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2}}
                                                                                                         itemId={`${emailDate}_${hub}_${deliveryFacility}`} label={<div style={{display: "flex", justifyContent: "space-between"}}><Typography>{deliveryFacility}</Typography><Typography>{deliveryFacilitySummary?._total}</Typography></div>}>
                                                {Object.entries(deliveryFacilitySummary).map(([filepath, palletCount]) => {
                                                    if (!filepath.startsWith("_")) return (<TreeItem sx={{backgroundColor: "#b3d9ff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2}} 
                                                                                                        itemId={`${emailDate}_${hub}_${deliveryFacility}_${filepath}`} label={<div style={{display: "flex", justifyContent: "space-between"}} onClick={() => {openFileViewer(filepath)}}><Typography>{extractFilenameFromPath(filepath)}</Typography><Typography>{palletCount}</Typography></div>}
                                                                                                        />)
                                                })}
                                            </TreeItem>)
                                        })}
                                    </TreeItem>)
                                })}
                            </TreeItem>
                        </SimpleTreeView>
                    ))}</>)}
                
                </Box>

                {/* FileViewer Dialog */}
                <FileViewer open={openFile} onClose={closeFileViewer} fileSource={fileSource} />
                
            </Box>
        </>
    )
}


const DashboardPage = () => {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newTabValue) => {
        setActiveTab(newTabValue);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/");
    };

    return (
        <Box sx={{paddingX: 5}}>
            {/* Header */}
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Box></Box>
                <Tooltip title="Logout"><IconButton color="primary" onClick={handleLogout}><ExitToAppIcon/></IconButton></Tooltip>
            </Box>
            {/* Title */}
            <Typography variant="h4" gutterBottom>
            Dashboard
            </Typography>
            <hr />
            <Box sx={{width: "100%"}}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Insights"/>
                    <Tab label="Summary"/>
                    <Tab label="Reports"/>
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <InsightsPage/>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <SummaryPage/>
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <ReportsPage/>
                </TabPanel>
            </Box>
        </Box>
    )
};

export default DashboardPage;
