import React, { useState } from 'react';
import { Box, Container, TextField, Button, Typography } from '@mui/material';
import { userLoginApi } from '../Common/apiCalls';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {

    const navigate = useNavigate();

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
    console.log('User ID:', userId);
    console.log('Password:', password);

    const responseData = await userLoginApi(userId, password);
    if (responseData.response_data) {
        sessionStorage.setItem("authToken", responseData.response_data);
        navigate("/dashboard");
    }
    };

  return (
    <Container maxWidth={false} sx={{ width: "100vw", height: '100vh', display: 'flex', padding: 0 }}>
      {/* Left Part with Blue Background */}
      <Box
        sx={{
          width: '50%',
          backgroundColor: 'rgb(63, 72, 204)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" color="white">
          AI for BOL
        </Typography>
      </Box>

      {/* Right Part with Grey Background */}
      <Box
        sx={{
          width: '50%',
          backgroundColor: '#dedede',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '300px',
          }}
        >
          <TextField
            label="User ID"
            variant="outlined"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            sx={{ marginTop: 2 }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
