import React, { useState, useEffect } from "react";
import { useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, TextField, Box, Checkbox, FormControlLabel, Typography, Grid, CircularProgress, CardContent, Card, Select, MenuItem, FormControl, InputLabel, IconButton, Tooltip } from "@mui/material";
import { commonGetFileData } from "../Common/apiCalls";
import { base64toBlob, getMimeType } from "../Common/utils";
import { useNavigate } from "react-router-dom";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
// import PdfViewer2 from './PdfViewer2';
import PdfViewer from "./PdfViewer";

const EditableJsonDialog = ({ openDialog, handleCloseDialog, jsonData, jsonDataId, handleSave, fileSource, viewType }) => {
  const navigate = useNavigate();
  const [editableJsonData, setEditableJsonData] = useState({});
  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fileBlobUrl = useMemo(() => {
    if (fileData) {
      return URL.createObjectURL(base64toBlob(fileData));
    }
    return null;
  }, [fileData]); 

  // Initialize the editable jsonData when the dialog opens
  useEffect(() => {
    if (jsonData) {
      setEditableJsonData({ ...jsonData });
    }
    if (openDialog && fileSource && jsonDataId !== null) {
      refreshFile();
    }
  }, [jsonData, fileSource, openDialog, jsonDataId]);

  const refreshFile = async () => {
    setLoading(true);
    setError(false);

    // API call to fetch the base64 string for the file
    const apiResponse = await commonGetFileData(fileSource, handleAlert);
    if (apiResponse?.response_data) {
      const base64output = apiResponse.response_data;
      setFileData(base64output);
      setFileType(getMimeType(base64output));
    } else {
      setError(true);
      setFileData(null);
      setFileType(null);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!openDialog) {
  //     if (fileBlobUrl) {
  //       URL.revokeObjectURL(fileBlobUrl);
  //     }
  //   }
  // }, [openDialog, fileBlobUrl]);

  // Handle value changes in the form
  const handleValueChange = (key, value) => {
    if(key === "bol_type"){
      if(value === "Outbound"){
        handleValueChange("is_outbound", true)
      } else {
        handleValueChange("is_outbound", false)
      }
    }
    setEditableJsonData((prevData) => {
      if (key.includes('.')) {
        const keys = key.split('.');
        const index = parseInt(keys[1]);
        const field = keys[2];
  
        if (Array.isArray(prevData.item_details)) {
          // Create a deep copy of the item_details array to maintain immutability
          let updatedData = { ...prevData };  // Make a shallow copy of prevData (preserving other fields)
          updatedData.item_details = [...prevData.item_details]; // Create a copy of the item_details array

          updatedData.item_details[index] = {
            ...updatedData.item_details[index],
            [field]: value
          };
  
          return updatedData;
        }

        return { ...prevData, [key]: value };
      }

      return { ...prevData, [key]: value };
    });
  };

  // Add new item to item_details array
  const addItem = () => {
    setEditableJsonData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.item_details = [
        ...prevData.item_details,
        { item_name: '', no_of_units: '', total_weight: '' }
      ];
      return updatedData;
    });
  };

  // Delete an item from the item_details array
  const deleteItem = (index) => {
    setEditableJsonData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.item_details = prevData.item_details.filter((_, i) => i !== index);
      return updatedData;
    });
  };

  // Render editable form elements based on the type of value
  const renderEditableField = (key, value) => {
    if ((key === "bol_type" || key === "is_outbound") && (editableJsonData.hasOwnProperty("bol_type") && editableJsonData.hasOwnProperty("is_outbound"))) {
      if (key === "is_outbound") {
        return null;
      }
    }

    const isDisabled = (key === 'ship_date' && (editableJsonData.bol_type === "Inbound")) 
      || (key === 'delivery_facility_code' && (editableJsonData.bol_type === "Inbound")) 
      || (key === 'item_details' && (editableJsonData.bol_type === "Inbound"))
      || (key === 'received_date' && (editableJsonData.bol_type === "Outbound" || editableJsonData.bol_type === "Outbound-Shein-PPMOD"))
      || key === 'email_date' 
      || key === 'key';

    const labelMapping = {
      delivery_facility: editableJsonData.bol_type === "Inbound" ? "client_name" : "delivery_facility"
    };

    const displayLabel = labelMapping[key] || key;

    if (key === "bol_type") {
      return (
          <Box key={key}>
              <FormControl fullWidth variant="outlined">
                  <InputLabel id="bol-type-label">bol_type</InputLabel>
                  <Select
                      labelId="bol-type-label"
                      value={value}
                      onChange={(e) => handleValueChange(key, e.target.value)}
                      label={key}
                  >
                      <MenuItem value="Inbound">Inbound</MenuItem>
                      <MenuItem value="Outbound">Outbound</MenuItem>
                      <MenuItem value="Outbound-Shein-PPMOD">Sortation</MenuItem>
                      <MenuItem value="Unknown">Unknown</MenuItem>
                  </Select>
              </FormControl>
          </Box>
      );
    }

    if (typeof value === "string") {
      return <TextField key={key} label={displayLabel} value={value} onChange={(e) => handleValueChange(key, e.target.value)} fullWidth variant="outlined" disabled={isDisabled}/>;
    }

    if (typeof value === "number") {
      return <TextField key={key} label={displayLabel} type="number" value={value} onChange={(e) => handleValueChange(key, parseFloat(e.target.value))} fullWidth variant="outlined" disabled={isDisabled}/>;
    }

    if (typeof value === "boolean") {
      return <FormControlLabel key={key} control={<Checkbox checked={value} onChange={(e) => handleValueChange(key, e.target.checked)} />} label={displayLabel} variant="outlined" />;
    }

    if(key === "item_details" && value === null){
      return null
    }

    if (Array.isArray(value) && key === "item_details") {
      return (
        <Box key={key}>
          <strong>{key}</strong>
          <Box display="flex" flexDirection="column" margin={1}>
            {value.map((nestedValue, index) => {
              const { item_name, no_of_units, total_weight } = nestedValue;

              return (
                <Box key={index} display="flex" flexDirection="row" width="100%" margin={1} justifyContent="space-between">
                  {/* Render Item Name */}
                  <Box flex="1" marginRight={1}>
                    <TextField
                      label="Item Name"
                      value={item_name}
                      onChange={(e) => handleValueChange(`${key}.${index}.item_name`, e.target.value)}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Box>

                  {/* Render No of Units */}
                  <Box flex="1" marginRight={1}>
                    <TextField
                      label="No of Units"
                      type="number"
                      value={no_of_units}
                      onChange={(e) => handleValueChange(`${key}.${index}.no_of_units`, parseFloat(e.target.value))}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Box>

                  {/* Render Total Weight */}
                  <Box flex="1">
                    <TextField
                      label="Total Weight"
                      type="number"
                      value={total_weight}
                      onChange={(e) => handleValueChange(`${key}.${index}.total_weight`, parseFloat(e.target.value))}
                      fullWidth
                      variant="outlined"
                      disabled={isDisabled}
                    />
                  </Box>

                  {/* Delete Button */}
                  <Box>
                    <Tooltip title="Delete Item">
                      <IconButton 
                        onClick={() => deleteItem(index)} 
                        sx={{ color: 'red' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box display="flex" justifyContent="flex-start" marginTop={1}>
            <Tooltip title="Add Item">
              <Button 
                onClick={addItem} 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />}
              >
                Add Item
              </Button>
            </Tooltip>
          </Box>
        </Box>
      );
    }

    return <TextField key={key} label={displayLabel} value={value} onChange={(e) => handleValueChange(key, e.target.value)} fullWidth variant="outlined" disabled={isDisabled}/>;
  };

  const handleSaveChanges = () => {
    // Pass the updated jsonData back through the handleSave prop
    setLoading(true);
    handleSave(editableJsonData, jsonDataId);
    if (viewType === "Dialog") handleCloseDialog();
  };

  const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
    if (isLoggedOut) {
      sessionStorage.clear();
      sessionStorage.setItem("authToken", "__logged_out__");
      navigate("/");
    }
  };

  // Controls component to handle zoom in, zoom out, and reset
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls(); // Destructure zoom control functions
    return (
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", marginBottom: "10px", pl: 2, gap: 1 }}>
        <Typography variant="body1">Image Controls:</Typography>
        <Button variant="contained" color="primary" onClick={() => zoomIn()} size="small">
          <ZoomInRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => zoomOut()} size="small">
          <ZoomOutRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => resetTransform()} size="small">
          <RestoreRoundedIcon />
        </Button>
      </Box>
    );
  };

  const content = (
    <>
      <Grid container spacing={2}>
        {/* Left side - File Viewer */}
        <Grid item xs={12} md={7}>
          <Box sx={{ textAlign: "center" }}>
            {viewType === "Card" && <Box sx={{ fontSize: 20, fontWeight: "bold", mb: 2 }}>Original BOL File</Box>}
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Typography>No file available</Typography>
            ) : fileData ? (
              fileType === "pdf" ? (
                <div>
                  <PdfViewer pdfUrl={fileBlobUrl} />
                </div>
              ) : (
                <TransformWrapper>
                  {/* Zoom Controls */}
                  <Controls />
                  <TransformComponent>
                    <img
                      src={`${fileData}`}
                      alt="File Content"
                      style={{
                        width: "100%",
                        maxHeight: "700px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                    />
                  </TransformComponent>
                </TransformWrapper>
              )
            ) : null}
          </Box>
        </Grid>

        {/* Right side - Editable JSON Data */}
        <Grid item xs={12} md={5}>
          {viewType === "Card" && <Box sx={{ fontSize: 20, fontWeight: "bold", mb: 2, textAlign: "center" }}>Edit Extracted Output</Box>}
          {editableJsonData && !loading && (
            <Box sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
              {Object.keys(editableJsonData).map((key) => {
                const value = editableJsonData[key];
                return (
                  <Box sx={{ marginY: 2, width: "100%" }} key={key}>
                    {renderEditableField(key, value)}
                  </Box>
                );
              })}
            </Box>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", pr: 2, gap: 2 }}>
        {viewType === "Dialog" && <Button onClick={handleCloseDialog}>Close</Button>}
        {!loading && (
          <Button variant="contained" onClick={handleSaveChanges} color="primary">
            Save
          </Button>
        )}
      </Box>
    </>
  );

  return viewType === "Dialog" ? (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Extracted Output</DialogTitle>
      <DialogContent>{content}</DialogContent>{" "}
    </Dialog>
  ) : (
    <Card sx={{ height: "auto", width: "60vw", my: 1, boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)" }}>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

export default EditableJsonDialog;
